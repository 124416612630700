// import PropTypes from 'prop-types';

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppAppBar from './components/AppAppBarMemorial';
import getLPTheme from './getLPTheme';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './global.css';
import Button from '@mui/material/Button';

import { alpha } from '@mui/material';
import Container from '@mui/material/Container';


import QRCodeStyling from "qr-code-styling";

import React, { useEffect, useRef, useState } from "react";



import { useParams } from 'react-router-dom';


export default function MemorialPage() {
  const [mode, setMode] = React.useState('light');
  const LPtheme = createTheme(getLPTheme(mode));
  const ref = useRef(null);
  let { identifier } = useParams();

  const [options] = useState({
    width: 300,
    height: 300,
    type: 'svg',
    data: 'https://global.memorial/m/' + identifier,
    image: '/qrlogo.png',
    margin: 3,
    qrOptions: {
      typeNumber: 0,
      mode: 'Byte',
      errorCorrectionLevel: 'H'
    },
    imageOptions: {
      hideBackgroundDots: true,
      imageSize: 0.2,
      margin: 0,
      crossOrigin: 'anonymous',
    },
    dotsOptions: {
      color: '#000',
      // gradient: {
      //   type: 'linear', // 'radial'
      //   rotation: 0,
      //   colorStops: [{ offset: 0, color: '#8688B2' }, { offset: 1, color: '#77779C' }]
      // },
      type: 'square'
    },
    backgroundOptions: {
      color: '#ffffff',
      // gradient: {
      //   type: 'linear', // 'radial'
      //   rotation: 0,
      //   colorStops: [{ offset: 0, color: '#ededff' }, { offset: 1, color: '#e6e7ff' }]
      // },
    },
    cornersSquareOptions: {
      color: '#000',
      type: 'square',
      // gradient: {
      //   type: 'linear', // 'radial'
      //   rotation: 180,
      //   colorStops: [{ offset: 0, color: '#25456e' }, { offset: 1, color: '#4267b2' }]
      // },
    },
    cornersDotOptions: {
      color: '#222222',
      type: 'dot',
      // gradient: {
      //   type: 'linear', // 'radial'
      //   rotation: 180,
      //   colorStops: [{ offset: 0, color: '#00266e' }, { offset: 1, color: '#4060b3' }]
      // },
    }
  });

  const [qrCode] = useState(new QRCodeStyling(options));
  useEffect(() => {
    if (ref.current) {
      qrCode.append(ref.current);
    }
  }, [qrCode, ref]);


  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  const onDownloadClick = () => {
    if (qrCode) {
      qrCode.download({
        name: identifier,
        extension: 'svg'
      });
    }

  }


  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Box sx={{ bgcolor: 'background.default' }}>
        <Box
          id="hero"
          sx={(theme) => ({
            width: '100%',
            backgroundImage:
              theme.palette.mode === 'light'
                ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
                : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
            backgroundSize: '100% 20%',
            backgroundRepeat: 'no-repeat',
          })}
        >
          <Container
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              pt: { xs: 14, sm: 20 },
              pb: { xs: 8, sm: 12 },
            }}
          >
          <div ref={ref} />
            <Button onClick={onDownloadClick} variant="contained" color="primary" >
              Download QR Code
            </Button>

          </Container>
        </Box>

      </Box>
    </ThemeProvider>
  );
}