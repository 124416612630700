import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import getLPTheme from './getLPTheme';
import { useNavigate } from 'react-router-dom';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './global.css';
import { useTranslation } from 'react-i18next';

export default function NotFoundPage() {
  const [mode] = React.useState('light');
  const LPtheme = createTheme(getLPTheme(mode));
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', // Fill the screen height
          bgcolor: 'background.default',
          color: 'text.primary',
          p: 3, // Padding
        }}
      >
        <Typography variant="h1" component="h1" gutterBottom>
          404
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          {t('PageNotFound')}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {t('PageNotFoundSecond')}
        </Typography>
        <Button 
          variant="contained" 
          onClick={() => navigate('/')}
          sx={{ mt: 3 }} // Margin top
        >
          {t('HomePageGoBack')}
        </Button>
      </Box>
    </ThemeProvider>
  );
}
