import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/700.css';
import './global.css';
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import NotFoundPage from './NotFound';

import MemorialPage from './Memorial';

import * as Sentry from "@sentry/react";
import { SnackbarProvider } from 'notistack'


Sentry.init({
  dsn: "https://08aa10fc3be732a0d12a8d19c8622a49@o4507086519795712.ingest.de.sentry.io/4507086522417232",
  integrations: [],
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <NotFoundPage/>,
  },
  {
    path: "/m/:identifier",
    element: <MemorialPage />,
  }
]);

i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) 
  .init({
    fallbackLng: "pl",
    debug: true,

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <SnackbarProvider maxSnack={3} 
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      >
    <RouterProvider router={router} />
    </SnackbarProvider>
    
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
