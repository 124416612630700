import { createClient } from "@supabase/supabase-js";

const supabase = createClient("https://api.global.memorial", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVwanBkdWV1Ymxqem9peW1lZWdlIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI0ODIyMjQsImV4cCI6MjAyODA1ODIyNH0._xEaQcv1fgHy1ChPWD-SGqXWhPY_Q3odDxjbW8pRnf4");

async function save_marketing_email(email) {
  const result = await supabase.rpc("add_email_to_marketing", { email_address: email });
  return result
}

export default supabase;

export { save_marketing_email };